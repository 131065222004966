import { Tooltip } from '@material-ui/core';
import { Tags, TagsFill } from 'react-bootstrap-icons';
import { numberFormatCurrency } from '../../utils/helper';

export const inventoriesCol = [
  {
    field: 'transaction_date',
    headerName: 'Date',
    width: 150,
    sortable: false,
  },
  { field: 'reference', headerName: 'Reference', width: 150, sortable: false },
  { field: 'source', headerName: 'Source', width: 150, sortable: false },
  {
    field: 'particulars',
    headerName: 'Particulars',
    width: 150,
    sortable: false,
  },

  {
    field: 'purchases_unit',
    headerName: 'Purchases Unit',
    width: 200,
    sortable: false,
  },
  {
    field: 'purchases_cost',
    headerName: 'Purchases Cost',
    width: 200,
    sortable: false,
  },
  {
    field: 'purchases_total',
    headerName: 'Purchases Total',
    width: 200,
    sortable: false,
  },

  {
    field: 'sold_unit',
    headerName: 'Sold Unit',
    width: 200,
    sortable: false,
  },
  {
    field: 'sold_cost',
    headerName: 'Sold Cost',
    width: 200,
    sortable: false,
  },
  {
    field: 'sold_total',
    headerName: 'Sold Total',
    width: 200,
    sortable: false,
  },

  {
    field: 'adjustment_unit',
    headerName: 'Adjustment Unit',
    width: 200,
    sortable: false,
  },
  {
    field: 'adjustment_cost',
    headerName: 'Adjustment Cost',
    width: 200,
    sortable: false,
  },
  {
    field: 'adjustment_total',
    headerName: 'Adjustment Total',
    width: 200,
    sortable: false,
  },

  {
    field: 'inventory_transfer_unit',
    headerName: 'Inventory Transfer Unit',
    width: 200,
    sortable: false,
  },
  {
    field: 'inventory_transfer_cost',
    headerName: 'Inventory Transfer Cost',
    width: 200,
    sortable: false,
    type: 'number',
    renderCell: ({ row }) => {
      numberFormatCurrency(row.inventory_transfer_cost);
    },
  },
  {
    field: 'inventory_transfer_total',
    headerName: 'Inventory Transfer Total',
    width: 200,
    sortable: false,
    type: 'number',
    renderCell: ({ row }) => {
      numberFormatCurrency(row.inventory_transfer_total);
    },
  },

  {
    field: 'overall_balance_unit',
    headerName: 'Overall Unit',
    width: 200,
    sortable: false,
    type: 'number',
    renderCell: ({ row }) => {
      numberFormatCurrency(row.overall_balance_unit);
    },
  },
  {
    field: 'overall_balance_cost',
    headerName: 'Overall Cost',
    width: 200,
    sortable: false,
    type: 'number',
    renderCell: ({ row }) => {
      numberFormatCurrency(row.overall_balance_cost);
    },
  },
  {
    field: 'overall_balance_total',
    headerName: 'Overall Total',
    flex: 1,
    sortable: false,
    type: 'number',
    renderCell: ({ row }) => {
      numberFormatCurrency(row.overall_balance_total);
    },
  },
];

export const receivablesCol = [
  {
    field: 'customer_code',
    headerName: 'Customer Code',
    width: 200,
    sortable: false,
  },
  {
    field: 'customer_name',
    headerName: 'Customer Account',
    width: 200,
    sortable: false,
  },
  {
    field: 'transaction_type',
    headerName: 'Transaction',
    width: 150,
    sortable: false,
  },
  {
    field: 'transaction_date',
    headerName: 'Transaction Date',
    width: 150,
    sortable: false,
  },
  {
    field: 'si_number',
    headerName: 'Printed SI No',
    width: 150,
    sortable: false,
  },
  {
    field: 'digital_si',
    headerName: 'Digital SI No',
    width: 150,
    sortable: false,
  },
  { field: 'dr_no', headerName: 'Dr No', width: 150, sortable: false },
  {
    field: 'reference',
    headerName: 'Reference No',
    width: 150,
    sortable: false,
  },
  {
    field: 'particulars',
    headerName: 'Particulars',
    width: 185,
    sortable: false,
  },
  // {
  //   field: "beg_balance",
  //   headerName: "Beginning Balance",
  //   minWidth: 200,
  //   type: "number",
  // },
  {
    field: 'receivable_debit',
    headerName: 'Debit',
    width: 200,
    type: 'number',
    sortable: false,
    renderCell: ({ row }) => {
      numberFormatCurrency(row.receivable_debit);
    },
  },
  {
    field: 'receivable_credit',
    headerName: 'Credit',
    minWidth: 200,
    type: 'number',
    sortable: false,
    renderCell: ({ row }) => {
      numberFormatCurrency(row.receivable_credit);
    },
  },
  {
    field: 'receivable_balance',
    headerName: 'Balance',
    type: 'number',
    minWidth: 200,
    sortable: false,
    renderCell: ({ row }) => {
      numberFormatCurrency(row.receivable_balance);
    },
  },
  {
    field: 'running_balance',
    headerName: 'Running Balance',
    type: 'number',
    minWidth: 200,
    sortable: false,
    renderCell: ({ row }) => {
      numberFormatCurrency(row.running_balance);
    },
  },
];

export const salesDataCol = [
  {
    field: 'transaction_date',
    headerName: 'Date',
    width: 150,
    sortable: false,
  },
  { field: 'si_number', headerName: 'Printed SI Number', width: 150 },
  { field: 'digital_si', headerName: 'Digital SI Number', width: 150 },
  {
    field: 'transaction_type',
    headerName: 'Transaction Type',
    width: 185,
  },
  {
    field: 'customer_name',
    headerName: 'Customer Account',
    width: 200,
  },
  {
    field: 'customer_code',
    headerName: 'Customer Code',
    width: 200,
  },
  {
    field: 'gross',
    headerName: 'Gross',
    width: 200,
  },
  {
    field: 'net',
    headerName: 'Net',
    width: 200,
  },
  {
    field: 'sales_debit',
    headerName: 'Debit',
    width: 200,
    type: 'number',
    renderCell: ({ row }) => {
      numberFormatCurrency(row.sales_debit);
    },
  },
  {
    field: 'sales_credit',
    headerName: 'Credit',
    width: 200,
    type: 'number',
    renderCell: ({ row }) => {
      numberFormatCurrency(row.sales_credit);
    },
  },
  {
    field: 'sales_balance',
    headerName: 'Balance',
    type: 'number',
    width: 200,
    renderCell: ({ row }) => {
      numberFormatCurrency(row.sales_balance);
    },
  },
];

export const salesPerProductDataCol = [
  { field: 'booked_by', headerName: 'Booked By', width: 150 },
  { field: 'year', headerName: 'Year', width: 150 },
  { field: 'month', headerName: 'Month', width: 150 },
  { field: 'si_date', headerName: 'SI Date', width: 150 },
  { field: 'dr_number', headerName: 'DR Number', width: 150 },
  { field: 'digital_si_number', headerName: 'Digital SI Number', width: 150 },
  { field: 'printed_si_number', headerName: 'Printed SI Number', width: 150 },
  {
    field: 'customer_name',
    headerName: 'Customer Account',
    width: 200,
    sortable: true,
  },
  {
    field: 'so_number',
    headerName: 'SO Number',
    width: 200,
  },
  {
    field: 'is_promotional',
    headerName: 'Order Type',
    width: 300,
    renderCell: ({ row }) => {
      if (row.is_promotional === 0) {
        return <span className="badge bg-success">Normal Orders</span>;
      } else {
        return (
          <span className="badge bg-warning">
            Special Promo/Near Expiry Order
          </span>
        );
      }
    },
  },
  {
    field: 'channel',
    headerName: 'Channel',
    width: 200,
  },
  {
    field: 'segment',
    headerName: 'Segment',
    width: 200,
  },
  {
    field: 'product_code',
    headerName: 'Product Code',
    width: 200,
  },
  {
    field: 'product',
    headerName: 'Product',
    width: 200,
  },
  {
    field: 'batch_no',
    headerName: 'Batch No.',
    width: 200,
  },
  {
    field: 'exp_date',
    headerName: 'Exp. Date',
    width: 200,
  },
  {
    field: 'qty',
    headerName: 'QTY',
    width: 100,
  },
  {
    field: 'fg',
    headerName: 'FG',
    width: 150,
  },

  {
    field: 'price',
    headerName: 'Price',
    width: 200,
    type: 'number',
    renderCell: ({ row }) => {
      numberFormatCurrency(row.price);
    },
  },
  {
    field: 'dsc',
    headerName: 'Dsc',
    width: 100,
    sortable: false,
  },
  {
    field: 'gross',
    headerName: 'Gross',
    width: 200,
    type: 'number',
    renderCell: ({ row }) => {
      numberFormatCurrency(row.gross);
    },
  },
  {
    field: 'net',
    headerName: 'Net',
    width: 200,
    sortable: false,
    type: 'number',
    renderCell: ({ row }) => {
      numberFormatCurrency(row.net);
    },
  },
];

export const vatableSalesCol = [
  { field: 'si_date', headerName: 'Date', width: 150 },
  { field: 'dr_number', headerName: 'DR No', width: 150 },
  { field: 'si_number', headerName: 'SI No', width: 150 },
  {
    field: 'customer_name',
    headerName: 'Customer Account',
    width: 200,
    sortable: true,
  },
  {
    field: 'tin',
    headerName: 'TIN',
    width: 185,
  },
  {
    field: 'net_sales',
    headerName: 'TOTAL NET SALES',
    width: 230,
    sortable: false,
    type: 'number',
    renderCell: ({ row }) => {
      numberFormatCurrency(row.net_sales);
    },
  },
  {
    field: 'vat_sales',
    headerName: 'VATABLE SALES ',
    width: 230,
    sortable: false,
    type: 'number',
    renderCell: ({ row }) => {
      numberFormatCurrency(row.vat_sales);
    },
  },
  {
    field: 'rated_sales',
    headerName: 'VAT ZERO (0%) RATED SALES',
    width: 230,
    sortable: false,
    type: 'number',
    renderCell: ({ row }) => {
      numberFormatCurrency(row.rated_sales);
    },
  },
  {
    field: 'vat_exempt',
    headerName: 'VAT EXEMPT SALES ',
    width: 230,
    sortable: false,
    type: 'number',
    renderCell: ({ row }) => {
      numberFormatCurrency(row.vat_exempt);
    },
  },
  {
    field: 'vat_amount',
    headerName: 'VAT AMOUNT SALES ',
    width: 230,
    sortable: false,
    type: 'number',
    renderCell: ({ row }) => {
      numberFormatCurrency(row.vat_amount);
    },
  },
  {
    field: 'sales',
    headerName: 'SALES (NET of VAT)',
    sortable: false,
    minWidth: 250,
    type: 'number',
    renderCell: ({ row }) => {
      numberFormatCurrency(row.sales);
    },
  },
];

export const generalLedgerCol = [
  {
    field: 'id',
    headerName: 'ID number',
    width: 200,
    sortable: false,
  },
  {
    field: 'journal_entry',
    headerName: 'Journal Entry No',
    width: 200,
    sortable: false,
  },
  {
    field: 'transaction_date',
    headerName: 'Transaction Date',
    width: 150,
    sortable: false,
  },
  {
    field: 'account_code',
    headerName: 'Account Number',
    width: 150,
    sortable: false,
  },
  {
    field: 'account_name',
    headerName: 'Account Description',
    width: 150,
    sortable: false,
  },
  {
    field: 'debit',
    headerName: 'Debit',
    width: 200,
    type: 'number',
    sortable: false,
    filterable: false,
    renderCell: ({ row }) => {
      return numberFormatCurrency(row.debit, '');
    },
  },
  {
    field: 'credit',
    headerName: 'Credit',
    minWidth: 200,
    type: 'number',
    sortable: false,
    filterable: false,
    renderCell: ({ row }) => {
      return numberFormatCurrency(row.credit, '');
    },
  },
  {
    field: 'running_balance',
    headerName: 'Running Balance',
    minWidth: 200,
    type: 'number',
    sortable: false,
    filterable: false,
    renderCell: ({ row }) => {
      return numberFormatCurrency(row.running_balance, '');
    },
  },
  {
    field: 'module',
    headerName: 'Module',
    width: 150,
    sortable: false,
  },
  {
    field: 'reference',
    headerName: 'Module Reference',
    width: 150,
    sortable: false,
  },
  {
    field: 'payee',
    headerName: 'Payee/Supplier',
    width: 150,
    sortable: false,
  },
  {
    field: 'md',
    headerName: 'MD',
    width: 150,
    sortable: true,
  },
  {
    field: 'particulars',
    headerName: 'Particulars',
    width: 185,
    sortable: false,
  },
  {
    field: 'remarks',
    headerName: 'Remarks',
    width: 185,
    sortable: false,
  },
  {
    field: 'details',
    headerName: 'Details',
    width: 185,
    sortable: false,
  },
  {
    field: 'program',
    headerName: 'Program',
    width: 185,
    sortable: false,
  },
  {
    field: 'cost_center',
    headerName: 'Cost Center',
    width: 185,
    sortable: false,
  },
];
// export const generalLedgerCol = [
//   {
//     field: 'fs_group',
//     headerName: 'FS Group',
//     width: 200,
//     sortable: true,
//   },
//   {
//     field: 'account_group',
//     headerName: 'Group',
//     width: 200,
//     sortable: true,
//   },
//   {
//     field: 'sub_group',
//     headerName: 'Subgroup',
//     width: 150,
//     sortable: true,
//   },
//   {
//     field: 'account_code',
//     headerName: 'Account Code',
//     width: 150,
//     sortable: true,
//   },
//   {
//     field: 'account_name',
//     headerName: 'Account Title',
//     width: 150,
//     sortable: true,
//   },
//   {
//     field: 'transaction_date',
//     headerName: 'Date',
//     width: 150,
//     sortable: true,
//   },
//   {
//     field: 'module',
//     headerName: 'Module',
//     width: 150,
//     sortable: true,
//   },
//   {
//     field: 'reference',
//     headerName: 'Reference',
//     width: 150,
//     sortable: true,
//   },
//   {
//     field: 'payee',
//     headerName: 'Payee',
//     width: 150,
//     sortable: true,
//   },
//   {
//     field: 'beginning',
//     headerName: 'Beginning',
//     minWidth: 200,
//     type: 'number',
//     sortable: false,
//     filterable: false,
//     renderCell: ({ row }) => {
//       return numberFormatCurrency(row.beginning, '');
//     },
//   },
//   {
//     field: 'debit',
//     headerName: 'Debit',
//     width: 200,
//     type: 'number',
//     sortable: false,
//     filterable: false,
//     renderCell: ({ row }) => {
//       return numberFormatCurrency(row.debit, '');
//     },
//   },
//   {
//     field: 'credit',
//     headerName: 'Credit',
//     minWidth: 200,
//     type: 'number',
//     sortable: false,
//     filterable: false,
//     renderCell: ({ row }) => {
//       return numberFormatCurrency(row.credit, '');
//     },
//   },
//   {
//     field: 'ending_balance',
//     headerName: 'Ending',
//     minWidth: 200,
//     type: 'number',
//     sortable: false,
//     filterable: false,
//     renderCell: ({ row }) => {
//       return numberFormatCurrency(row.ending_balance, '');
//     },
//   },
//   {
//     field: 'particulars',
//     headerName: 'Particulars',
//     width: 185,
//     sortable: true,
//   },
//   {
//     field: 'remarks',
//     headerName: 'Remarks',
//     width: 185,
//     sortable: true,
//   },
//   {
//     field: 'details',
//     headerName: 'Details',
//     width: 185,
//     sortable: true,
//   },
//   {
//     field: 'cost_center',
//     headerName: 'Cost Center',
//     width: 185,
//     sortable: true,
//   },
//   {
//     field: 'program',
//     headerName: 'Program',
//     width: 185,
//     sortable: true,
//   },
// ];
